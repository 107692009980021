import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

const FormSection = styled(Section)`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: 600;
  @media (max-width: 768px) {
    margin-top: 3rem;
    font-size: 22px;
  }
`;

const Email = styled.div`
  margin-top: auto;
  @media (max-width: 768px) {
    margin-top: 4rem;
  }
`;

const Text = styled.div`
  font-weight: 300;
  @media (max-width: 768px) {
    margin: 1rem 1rem 1rem 0;
  }
`;

const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  & input,
  textarea {
    border: none;
    background-color: inherit;
    border-bottom: 1px solid black;
    margin: 1rem 1rem 1rem 0;
    padding: 0.5rem;
    font-size: 16px;
    font-family: inherit;
    ::placeholder {
      /* color: #3d4e62; */
    }
    @media (max-width: 768px) {
      margin: 1.5rem 0 1.5rem 0;
    }
  }
  textarea:focus,
  input:focus {
    outline: none;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
`;

const UserDetails = styled.div`
  display: flex;
`;

const NameInput = styled.input`
  width: 50%;
`;

const EmailInput = styled.input`
  width: 50%;
  @media (max-width: 768px) {
    margin-left: 1.5rem !important;
  }
`;

const SubjectInput = styled.input``;

const MessageInput = styled.textarea``;

const SubmitButton = styled.button`
  border: none;
  width: 130px;
  color: #f4f3ef;
  background-color: #0073ea;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  margin: 1.5rem 1rem 0rem 0;
  font-family: inherit;
  &:hover {
    cursor: pointer;
    background-color: #0165cf;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 1rem 2rem 2rem 0;
  }
`;

const ErrorMessage = styled(Text)`
  position: absolute;
  bottom: 27px;
  color: #c13939;
  font-size: 14px;
  margin: 1rem 1rem 1.5rem 0;
  @media (max-width: 768px) {
    bottom: 57px;
  }
`;

const Contact = () => {
  const form = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [isSubmitFailed, setIsSubmitFailed] = useState(false);
  const [isFormNotValid, setIsFormNotValid] = useState(false);
  const [contactMessage, setContactMessage] = useState({
    full_name: '',
    user_email: '',
    subject: 'Contact us form- appSolution',
    message: '',
  });

  const handleInputChange = (e: any) => {
    setContactMessage((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const sendEmail = async (e: any) => {
    e.preventDefault();
    setIsFormNotValid(false);
    if (!contactMessage.user_email || !contactMessage.message) {
      setIsFormNotValid(true);
      return;
    }
    setLoading(true);
    setIsSubmitFailed(false);
    const response = await emailjs.sendForm(
      'service_93jefvr',
      'template_rp916td',
      form.current,
      'iavOX7fhnB6uZ8Cdy'
    );
    if (response.text !== 'OK') {
      setIsSubmitFailed(true);
      return;
    }
    setContactMessage({
      full_name: '',
      user_email: '',
      subject: '',
      message: '',
    });
    setLoading(false);
  };

  return (
    <Container>
      <Section>
        <Title>Get in touch</Title>
        {/* <Email>
          Stay in touch
          <br />
          contact@appsolution.live
        </Email> */}
      </Section>
      <FormSection>
        <Form ref={form} onSubmit={sendEmail}>
          <Text>Fill free to leave us a message</Text>
          <UserDetails>
            <NameInput
              name="full_name"
              value={contactMessage.full_name}
              onChange={(e) => handleInputChange(e)}
              type="text"
              placeholder="Your Name"
            />
            <EmailInput
              name="user_email"
              value={contactMessage.user_email}
              onChange={(e) => handleInputChange(e)}
              type="email"
              placeholder="Email*"
            />
          </UserDetails>
          {/* <SubjectInput
            name="subject"
            value={contactMessage.subject}
            onChange={(e) => handleInputChange(e)}
            placeholder="Subject"
          /> */}
          <MessageInput
            name="message"
            value={contactMessage.message}
            onChange={(e) => handleInputChange(e)}
            placeholder="Your Message*"
            rows={2}
          />
          {isFormNotValid && (
            <ErrorMessage>
              *Please fill in email and message fields
            </ErrorMessage>
          )}
          {isSubmitFailed && (
            <ErrorMessage>
              *Something went wrong, please try again later
            </ErrorMessage>
          )}
          <SubmitButton type="submit" disabled={loading ? true : false}>
            {loading ? 'Sending' : 'Submit'}
          </SubmitButton>
        </Form>
      </FormSection>
    </Container>
  );
};

export default Contact;
