import { useState } from 'react';
import styled from 'styled-components';
import menu_arrow_icon from '../../../../assets/icons/menu_arrow_icon.svg';

interface MenuProps {
  isOpen: boolean;
}

const Container = styled.div<MenuProps>`
  padding: 0.5rem 1.5rem;
  height: ${(props) => (props.isOpen ? '230px' : '40px')};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

const ArrowIcon = styled.div<MenuProps>`
  background-image: url(${menu_arrow_icon});
  background-size: contain;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: all 0.2s ease-in-out;
`;

const Text = styled.div`
  font-size: 16px;
  padding-left: 1rem;
`;

const Menu = styled.div`
  margin: 1rem 0;
`;

const MenuItem = styled.div`
  padding: 0.7rem 0;
  font-size: 16px;
`;

interface MobileSidebarProps {
  handleMenuClick: (event: any, element: string) => void;
}

const MobileSideBar = ({ handleMenuClick }: MobileSidebarProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMobileMenuClick = (event: any, element: string) => {
    setIsOpen((prevState) => !prevState);
    handleMenuClick(event, element);
  };
  return (
    <Container isOpen={isOpen}>
      <Header onClick={() => setIsOpen((prevState) => !prevState)}>
        <ArrowIcon isOpen={isOpen} />
        <Text>Menu</Text>
      </Header>
      {isOpen && (
        <Menu>
          <MenuItem
            className="menu-item"
            onClick={(event) => {
              handleMobileMenuClick(event, 'installation');
            }}
          >
            Installation
          </MenuItem>
          <MenuItem
            className="menu-item"
            onClick={(event) => {
              handleMobileMenuClick(event, 'get-started');
            }}
          >
            Get started
          </MenuItem>
          <MenuItem
            className="menu-item"
            onClick={(event) => {
              handleMobileMenuClick(event, 'supported-events');
            }}
          >
            Supported events
          </MenuItem>
          <MenuItem
            className="menu-item"
            onClick={(event) =>
              handleMobileMenuClick(event, 'supported-columns')
            }
          >
            Supported columns
          </MenuItem>
        </Menu>
      )}
    </Container>
  );
};

export default MobileSideBar;
