import styled from 'styled-components';
import Layout from '../../shared/components/Layout/Layout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
    padding: 1.5rem;
    box-sizing: border-box;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgb(194, 194, 194);
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const Text = styled.div`
  font-weight: 300;
  font-size: 14px;
  text-align: justify;
  line-height: 22px;
  padding-bottom: 1rem;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const List = styled.ul`
  margin: 1rem 0;
`;

const ListItem = styled.li`
  margin: 0.5rem 0;
`;

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Container>
        <Title>Privacy Policy</Title>
        <Section>
          <Subtitle>Introduction</Subtitle>
          <Text>
            As The CREATOR (“Ourselves”, “We”, “Our” and “Us”), we are committed
            to respecting your privacy. The security and privacy of your
            information are of utmost importance to us. Whether you are a
            customer, potential customer, end-user, or simply visiting our
            website, we want you to feel confident in our ability to manage and
            protect your information. This Statement has been prepared to
            provide you with information on who we are and how we collect and
            contain your data. Please note that our site is intended for a
            general audience and is not designed to collect personal information
            from children or minors. We do not knowingly collect personal data
            from individuals under the age of 18. By using our website, you
            acknowledge that we may collect and use some of your Personal Data
            as described in this Statement. This Privacy Policy is an integral
            part of our terms of service.
          </Text>
        </Section>
        <Section>
          <Subtitle>Data security</Subtitle>
          <Text>
            We are dedicated to safeguarding the security of your Personal Data.
            To prevent unauthorized access or use, we utilize various security
            technologies and protocols. However, it is important to note that
            the collection, transmission, and storage of information can never
            be completely secure. Nonetheless, we take measures to ensure the
            presence of suitable security measures that protect your
            information. We continuously review our policies and adopt
            additional security features as required.
          </Text>
        </Section>
        <Section>
          <Subtitle>Information we may collect from you</Subtitle>
          <Text>
            Personal information refers to any information that can be used to
            identify an individual. We collect this information from you on a
            voluntary basis when you provide it to us directly or through our
            services and websites that you interact with. we use various tools,
            such as Mixpanel, to collect data. Additionally, we may obtain such
            information about you as part of our legitimate business activities.
            While providing your information is optional, in some cases, failing
            to do so may prevent you from using our services. For instance, if
            you do not provide your contact details, we may not be able to
            respond to your inquiry made through our "contact form". When you
            use our website and voluntarily share your information, we may
            collect and process the following details:
            <List>
              <ListItem>
                Personal identity information- This may include but is not
                limited to your full name, username or other identifying
                information, job title, date of birth, gender, and any other
                information that you choose to provide at your own discretion.
              </ListItem>
              <ListItem>
                Technical information- This may encompass details such as your
                internet protocol (IP) address, device type, browser type and
                version, screen resolution, time zone and location, browser
                plug-ins, operating system, and platform, as well as other
                technical information relating to the devices you use to access
                our website.
              </ListItem>
              <ListItem>
                Usage informarion- This includes data related to how you use our
                website, apps, and services.
              </ListItem>
              <ListItem>
                Contact information- This refers to information that can be used
                to contact you, such as your email address and telephone
                numbers.
              </ListItem>
            </List>
          </Text>
        </Section>
        <Section>
          <Subtitle>Data collecting</Subtitle>
          <Text>
            There are various ways in which we gather information from you,
            which include:
            <List>
              <ListItem>
                You can provide us with your Identity, Contact, and other
                Personal Data by completing forms such as "Contact us" or "app
                subscription," or by communicating with us via phone, email, or
                other means.
              </ListItem>
              <ListItem>
                As you engage with our website or our services, we may collect
                personal data automatically.
              </ListItem>
              <ListItem>
                We may collect Personal Data related to you from various
                third-party sources and public sources, including but not
                limited to advertising and social networks, search information
                providers, and analytics providers.
              </ListItem>
            </List>
          </Text>
        </Section>
        <Section>
          <Subtitle>Privacy</Subtitle>
          <Text>
            We reserve the right to track usage with web analytics software such
            as Google Analytics. Embedding Third-Party Products When our apps
            embed features from third-party products, the embedded feature will
            normally be subject to the privacy policy of the third-party vendor.
            Third-party vendors may also include analytics, monitoring and ad
            platforms trackers.
          </Text>
        </Section>
        <Section>
          <Subtitle>Third-party services</Subtitle>
          <Text>
            We engage with third-party services to enhance our products and
            services. For instance, within our application TextWiz, we utilize
            OpenAI's technology to manipulate text, providing powerful and
            dynamic text manipulation features to our users. Additionally, in
            our app Multi Embed, we integrate Iframely to enhance functionality.
            While we are diligent in selecting our partners and safeguarding
            your data, it's important to note that these third-party services
            operate under their own privacy policies and data handling
            practices. We recommend reviewing their policies to understand how
            they collect, use, and protect your data. Rest assured, your privacy
            and data security remain our top priorities.
          </Text>
        </Section>
        <Section>
          <Subtitle>Links to other websites</Subtitle>
          <Text>
            Our website may have links to external websites, plug-ins, and
            applications provided by third parties. By clicking on these links
            or enabling the connections, you acknowledge that third parties may
            collect or share information about you. We have no control over
            these third-party websites and their privacy policies. Thus, we
            cannot be held accountable for their practices. As you navigate away
            from our website, we recommend that you read the privacy policy of
            each website you visit.
          </Text>
        </Section>
        <Section>
          <Subtitle>Contact information</Subtitle>
          <Text>Our contact email address:</Text>
          <Text>
            <a href="mailto:contact@appsolution.live">
              contact@appsolution.live
            </a>
          </Text>
        </Section>
        <Section>
          <Subtitle>Update and changes of this agreement</Subtitle>
          <Text>
            We may modify this Statement at our sole discretion, and we reserve
            the right to do so. If we make any changes, we will post those
            changes on our website to inform you of the information we collect,
            how we may use it, and the circumstances under which we may disclose
            it. Your continued use of our website or services, or provision of
            data after any such changes have been made, indicates your
            acceptance and agreement to the modified Statement.
          </Text>
        </Section>
      </Container>
    </Layout>
  );
};

export default PrivacyPolicy;
