import styled from 'styled-components';
import SideBar from './SideBar';
import { Element, scroller } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import multi_embed_1 from '../../../../assets/images/multi_embed_1.png';
import multi_embed_2 from '../../../../assets/images/multi_embed_2.png';
import multi_embed_3 from '../../../../assets/images/multi_embed_3.png';
import multi_embed_4 from '../../../../assets/images/multi_embed_4.png';
import multi_embed_5 from '../../../../assets/images/multi_embed_5.png';

const Container = styled.div`
  display: flex;
  margin: 3rem 2rem 2rem 2rem;
  background-color: white;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  border-bottom: 1px solid rgb(194, 194, 194);
  @media (max-width: 768px) {
    margin: 1.5rem;
  }
`;

const Hero = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid rgb(194, 194, 194);
  &:last-of-type {
    border-bottom: none;
  }
  @media (max-width: 768px) {
    margin: 1.5rem;
  }
`;

const GetStatrted = styled.div`
  display: flex;
  flex-direction: column;
`;

const SidebarSection = styled.div`
  width: 220px;
  @media (max-width: 768px) {
    width: unset;
    position: sticky;
    top: 0px;
    background-color: #ebeae6;
    border-bottom: 1px solid #ebeae6;
    backdrop-filter: blur(8px);
  }
`;

const Content = styled.div`
  width: 75%;
  padding: 0 2rem;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

const HeroTitle = styled.div`
  font-weight: 600;
  font-size: 32px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 26px;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Text = styled.div`
  font-weight: 300;
  text-align: justify;
  line-height: 22px;
  padding-bottom: 1rem;
`;

interface ImageProps {
  width?: number;
}

const Image = styled.img<ImageProps>`
  width: ${(props) => (props.width ? `${props.width}%` : '65%')};
  border-radius: 5px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  margin: 1.5rem auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const MultiEmbedHowToUseApp = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const scrollToElement = (element: string) => {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: isMobile ? -240 : -70,
    });
  };
  return (
    <Container>
      <SidebarSection>
        <SideBar scrollToElement={scrollToElement} />
      </SidebarSection>
      <Content>
        <Header>
          <Hero>
            <HeroTitle>How To Use</HeroTitle>
          </Hero>
          <Text>
            Goodbye Context Switching! Embed multiple tool URLs in one place!
            Drag & Drop, Resize & position them as you want. Your all-in-one
            workflow powerhouse.
          </Text>
          <Text>
            This document provides instructions on utilizing the application and
            outlines its features in detail.
          </Text>
        </Header>
        <Section>
          <Element name="installation">
            <Title>Installation</Title>
            <Text>
              1. Go to monday.com app marketplace or click the button below:
            </Text>
            <Text>
              <a href="https://auth.monday.com/oauth2/authorize?client_id=e14d4298aee9e22804146a06aa4364b7&response_type=install">
                <img
                  alt="Add to monday.com"
                  height="42"
                  src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                />
              </a>
            </Text>
            <Text>
              2. Search for <b>"Multi Embed"</b>
            </Text>
            <Text>
              3. Click <b>"Add to your account"</b> button
            </Text>
            <Text>
              4. Add Multi Embed to any board, item, dasboard you want.
            </Text>
            <GetStatrted>
              <Image src={multi_embed_1} width={90} alt="multi_embed_1" />
            </GetStatrted>
          </Element>
        </Section>
        <Section>
          <Element name="get-started">
            <GetStatrted>
              <Title>Get started</Title>
              <Text>
                With Multi Embed on your board, let's get started by adding your
                first embed URL!
              </Text>
              <Subtitle>Add your first URL</Subtitle>
              <Text>
                Open the menu by clicking on the blue button in the bottom right
                corner, then click on the "Add" option.
              </Text>
              <Image src={multi_embed_2} width={90} alt="multi_embed_2" />
              <Text>
                Enter the publicly accessible URL you would like to embed, then
                click 'Add'. That's it!
              </Text>
              <Text>
                You can add <b>multiple embed URLs</b> in the same view!
              </Text>
              <Image src={multi_embed_3} width={90} alt="multi_embed_3" />
              <Subtitle>Edit mode</Subtitle>
              <Text>
                Once you've added a new URL, you can arrange all embedded items
                by dragging and dropping them wherever you prefer. Resize them
                to suit your needs. When you're finished adjusting the embedded
                items, exit the edit mode by clicking the 'Stop' button.
              </Text>
              <Image src={multi_embed_4} width={90} alt="multi_embed_4" />
              <Subtitle>Boost your productivity</Subtitle>
              <Text>
                Now you are ready to stremline your workflow. No more context
                switching.
              </Text>
              <Image src={multi_embed_5} width={90} alt="multi_embed_5" />
            </GetStatrted>
          </Element>
        </Section>
      </Content>
    </Container>
  );
};

export default MultiEmbedHowToUseApp;
