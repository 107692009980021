import styled from 'styled-components';

const Table = styled.table`
  text-align: left;
  border-collapse: collapse;
  font-size: 15px;
  font-weight: 300;
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TableHead = styled.thead``;

const TableHeader = styled.th`
  border-bottom: 1px solid rgb(210, 210, 210);
  padding: 1rem 0;
  &:nth-child(1) {
    width: 50%;
  }
  &:nth-child(2) {
    width: 50%;
  }
`;

const TableRow = styled.tr`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

const TableBody = styled.tbody`
  ${TableRow} {
    border-bottom: 1px solid rgb(210, 210, 210);
    &:last-of-type {
      border-bottom: none;
    }
  }
`;

const TableData = styled.td`
  padding: 1rem 0;
  &:nth-child(1) {
    width: 50%;
  }
  &:nth-child(2) {
    width: 50%;
  }
`;
const SupportedColumnsTable = () => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader>Type</TableHeader>
          <TableHeader>Note</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableData>Name</TableData>
        </TableRow>
        <TableRow>
          <TableData>Status</TableData>
        </TableRow>
        <TableRow>
          <TableData>Text</TableData>
        </TableRow>
        <TableRow>
          <TableData>Long text</TableData>
        </TableRow>
        <TableRow>
          <TableData>Date</TableData>
        </TableRow>
        <TableRow>
          <TableData>Timeline</TableData>
        </TableRow>
        <TableRow>
          <TableData>People</TableData>
        </TableRow>
        <TableRow>
          <TableData>Numbers</TableData>
        </TableRow>
        <TableRow>
          <TableData>Email</TableData>
        </TableRow>
        <TableRow>
          <TableData>Link</TableData>
        </TableRow>
        <TableRow>
          <TableData>Location</TableData>
        </TableRow>
        <TableRow>
          <TableData>Connected boards</TableData>
          <TableData>There is no support for adding boards</TableData>
        </TableRow>
        <TableRow>
          <TableData>Dependency</TableData>
        </TableRow>
        <TableRow>
          <TableData>Dropdown</TableData>
        </TableRow>
        <TableRow>
          <TableData>Tag</TableData>
        </TableRow>
        <TableRow>
          <TableData>Rating</TableData>
        </TableRow>
        <TableRow>
          <TableData>Checkbox</TableData>
        </TableRow>
        <TableRow>
          <TableData>Country</TableData>
        </TableRow>
        <TableRow>
          <TableData>Week</TableData>
        </TableRow>
        <TableRow>
          <TableData>Hour</TableData>
        </TableRow>
        <TableRow>
          <TableData>World clock</TableData>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default SupportedColumnsTable;
