import styled from 'styled-components';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Container = styled.div`
  position: relative;
  background-color: #fff;
`;

const Main = styled.div`
  position: relative;
`;

interface LayoutProps {
  children: any;
}

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: false,
    });
  }, []);
  return (
    <Container>
      <Navbar />
      <Main data-aos="fade">{children}</Main>
      <Footer />
    </Container>
  );
};

export default Layout;
