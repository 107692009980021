import { StyledBurger } from './BurgerStyle';

interface BurgerProps {
  open: boolean;
  setOpen: any;
}

const Burger = ({ open, setOpen }: BurgerProps) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <span />
      <span />
      <span />
    </StyledBurger>
  );
};

export default Burger;
