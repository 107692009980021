export const languages = [
  { value: 'english', label: 'English' },
  { value: 'spanish', label: 'Spanish' },
  { value: 'french', label: 'French' },
  { value: 'german', label: 'German' },
  { value: 'arabic', label: 'Arabic' },
  { value: 'russian', label: 'Russian' },
  { value: 'chinese', label: 'Chinese' },
  { value: 'hebrew', label: 'Hebrew' },
  { value: 'japanese', label: 'Japanese' },
  { value: 'korean', label: 'Korean' },
  { value: 'portuguese', label: 'Portuguese' },
  { value: 'italian', label: 'Italian' },
  { value: 'dutch', label: 'Dutch' },
  { value: 'swedish', label: 'Swedish' },
  { value: 'norwegian', label: 'Norwegian' },
  { value: 'danish', label: 'Danish' },
  { value: 'finnish', label: 'Finnish' },
  { value: 'greek', label: 'Greek' },
  { value: 'turkish', label: 'Turkish' },
  { value: 'polish', label: 'Polish' },
  { value: 'hungarian', label: 'Hungarian' },
  { value: 'romanian', label: 'Romanian' },
  { value: 'czech', label: 'Czech' },
  { value: 'slovak', label: 'Slovak' },
  { value: 'bulgarian', label: 'Bulgarian' },
  { value: 'serbian', label: 'Serbian' },
  { value: 'croatian', label: 'Croatian' },
  { value: 'slovenian', label: 'Slovenian' },
  { value: 'ukrainian', label: 'Ukrainian' },
  { value: 'hindi', label: 'Hindi' },
  { value: 'bengali', label: 'Bengali' },
  { value: 'urdu', label: 'Urdu' },
  { value: 'punjabi', label: 'Punjabi' },
  { value: 'tamil', label: 'Tamil' },
  { value: 'telugu', label: 'Telugu' },
  { value: 'malayalam', label: 'Malayalam' },
  { value: 'kannada', label: 'Kannada' },
  { value: 'marathi', label: 'Marathi' },
  { value: 'gujarati', label: 'Gujarati' },
  { value: 'vietnamese', label: 'Vietnamese' },
  { value: 'thai', label: 'Thai' },
  { value: 'malay', label: 'Malay' },
  { value: 'indonesian', label: 'Indonesian' },
  { value: 'filipino', label: 'Filipino' },
  { value: 'persian', label: 'Persian' },
  { value: 'swahili', label: 'Swahili' },
  { value: 'amharic', label: 'Amharic' },
  { value: 'somali', label: 'Somali' },
  { value: 'igbo', label: 'Igbo' },
  { value: 'yoruba', label: 'Yoruba' },
];
