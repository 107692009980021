import styled from 'styled-components';
import SideBar from './SideBar';
import { Element, scroller } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import Layout from '../../../../shared/components/Layout/Layout';
import textwiz1 from '../../../../assets/images/textwiz1.png';
import textwiz2 from '../../../../assets/images/textwiz2.png';
import textwiz3 from '../../../../assets/images/textwiz3.png';
import textwiz4 from '../../../../assets/images/textwiz4.png';
import textwiz5 from '../../../../assets/images/textwiz5.png';
import textwiz6 from '../../../../assets/images/textwiz6.png';
import textwiz7 from '../../../../assets/images/textwiz7.png';
import { languages } from '../../../../shared/textwiz-translation-languages';

const Container = styled.div`
  display: flex;
  margin: 2rem 5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }
`;

const SidebarSection = styled.div`
  width: 210px;
  @media (max-width: 768px) {
    width: unset;
    position: sticky;
    top: 80px;
    background-color: #ebeae6;
    border-bottom: 1px solid #ebeae6;
    backdrop-filter: blur(8px);
  }
`;

const Content = styled.div`
  width: calc(100% - 210px);
  padding: 0 5rem;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

const Header = styled.div`
  padding-bottom: 3rem;
  border-bottom: 1px solid rgb(194, 194, 194);
  @media (max-width: 768px) {
    margin: 1.5rem;
  }
`;

const Hero = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const HeroTitle = styled.div`
  font-weight: 600;
  font-size: 32px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid rgb(194, 194, 194);
  &:last-of-type {
    border-bottom: none;
  }
  @media (max-width: 768px) {
    margin: 1.5rem;
  }
`;

const GetStatrted = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 26px;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Text = styled.div`
  font-weight: 300;
  text-align: justify;
  line-height: 22px;
  padding-bottom: 1rem;
`;

const Link = styled.a`
  color: #d5b343;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

interface ImageProps {
  width?: number;
}

const Image = styled.img<ImageProps>`
  width: ${(props) => (props.width ? `${props.width}%` : '65%')};
  border-radius: 5px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  margin: 1.5rem auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const LanguageList = styled.div`
  display: grid;
  grid-template-columns: repeat(6, auto);
  margin-top: 1rem;
  @media (max-width: 768px) {
    grid-template-columns: repeat(3, auto);
  }
`;

const TextWizHowToUseSite = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const scrollToElement = (element: string) => {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: isMobile ? -310 : -100,
    });
  };
  return (
    <Layout>
      <Container>
        <SidebarSection>
          <SideBar scrollToElement={scrollToElement} />
        </SidebarSection>
        <Content>
          <Header>
            <Hero>
              <HeroTitle>How To Use</HeroTitle>
            </Hero>
            <Text>
              TextWiz utilizes OpenAI's advanced capabilities to offer seamless
              text translation, summarization, rephrasing, and proofreading,
              optimizing project efficiency and teamwork.
            </Text>
            <Text>
              This document provides instructions on utilizing the application
              and outlines its features in detail.
            </Text>
          </Header>
          <Section>
            <Element name="installation">
              <Title>Installation</Title>
              <Text>
                1. Go to monday.com app marketplace or click the button below:
              </Text>
              <Text>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://auth.monday.com/oauth2/authorize?client_id=7613b98cf8981444d6a479ea384b3ad1&response_type=install"
                >
                  <img
                    alt="Add to monday.com"
                    height="42"
                    src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                  />
                </a>
              </Text>
              <Text>
                2. Search for <b>"TextWiz"</b>
              </Text>
              <Text>
                3. Click <b>"Add to your account"</b> button
              </Text>
              <Text>4. Add TextWiz to any board you want as item view</Text>
            </Element>
          </Section>
          <Section>
            <Element name="get-started">
              <GetStatrted>
                <Title>Get started</Title>
                <Text>
                  Now that you've added TextWiz as a item view, you can initiate
                  to translate, summarize, rephrase, and prooread your items
                  text columns. Just select the "TextWiz" item view to access
                  these functionalities.
                </Text>
                <Image src={textwiz1} width={80} alt="textwiz1" />
                <Text>
                  You can now pick from the main menu's four choices: translate,
                  summarize, rephrase, and proofread.
                </Text>
                <Image src={textwiz2} width={30} alt="textwiz2" />
                <Text>
                  After choosing your preferred action, you can select the text
                  or long-text columns on which you'd like to apply AI magic for
                  the chosen action. You can choose multiple columns
                  simultaneously, and then complete the process by clicking the
                  button located at the bottom of the screen.
                </Text>
                <Image src={textwiz3} width={50} alt="textwiz3" />
                <Text>Now, you simply await the AI's wizardry.</Text>
                <Image src={textwiz4} alt="textwiz4" />
                <Text>
                  Once the output is received from OpenAI for the requested
                  action, it will appear in the selected columns.
                </Text>
                <Image src={textwiz5} alt="textwiz5" />
                <Text>
                  At this point, you have the option to copy and edit the output
                  values of the columns.
                </Text>
                <Image src={textwiz6} alt="textwiz6" />
                <Text>
                  As a final step, you can take the following actions based on
                  the AI output: "Update selected item", "Create a new item",
                  "Create new columns" (or using columns that have already been
                  created by TextWiz) and "Create a new update". You can find
                  these actions displayed on the buttons located at the bottom
                  of the output screen.
                </Text>
                <Image src={textwiz7} alt="textwiz7" />
              </GetStatrted>
            </Element>
          </Section>
          <Section>
            <Element name="open-ai">
              <Title>Open AI</Title>
              <Text>
                TextWiz leverages advanced artificial intelligence capabilities
                through its seamless integration with the OpenAI API.
              </Text>
            </Element>
            <Element name="open-ai-model">
              <Subtitle>Model</Subtitle>
              <Text>
                Currently, the GPT-3.5-turbo-0613 model is in use within the
                TextWiz.
              </Text>
              <Text>
                GPT-3.5-turbo-0613 model is a highly advanced language model by
                OpenAI, capable of understanding and generating human-like text
                in multiple languages. It is versatile, suitable for
                applications like content generation, translation, and text
                completion, offering exceptional language processing
                capabilities to enhance various user experiences and streamline
                language-related tasks.
              </Text>
            </Element>
            <Element name="open-ai-usage">
              <Subtitle>Usage</Subtitle>
              <Text>
                TextWiz leverages the capabilities of the OpenAI API to perform
                various operations, and its usage is structured around plans
                that allocate a limited number of tokens to account. Tokens are
                essential units of text in language processing. To access a
                thorough explanation from the OpenAI website,{' '}
                <Link
                  target="_blank"
                  href="https://help.openai.com/en/articles/4936856-what-are-tokens-and-how-to-count-them#h_4ae02e0c88"
                >
                  click here
                </Link>
                .
              </Text>
              <Text>
                Every plan provides a specific monthly token allocation for the
                monday account to utilize the application. To check the most
                up-to-date plan,{' '}
                <Link target="_blank" href="">
                  click here
                </Link>
                .
              </Text>
            </Element>
          </Section>
          <Section>
            <Element name="translation-languages">
              <Title>Translation languages</Title>
              <Text>
                According to OpenAI's current capabilities, here is the list of
                languages available for text translation:
              </Text>
              <LanguageList>
                {languages.map((language, index) => (
                  <Text key={language.value}>{language.label}</Text>
                ))}
              </LanguageList>
            </Element>
          </Section>
        </Content>
      </Container>
    </Layout>
  );
};

export default TextWizHowToUseSite;
