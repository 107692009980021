import styled from 'styled-components';
import footer_app_solution_logo from '../../../assets/images/footer_app_solution_logo.svg';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../enums/RoutePaths';
import Contact from '../../../pages/Contact/Contact';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 5rem 0 1rem;
  box-sizing: border-box;
  width: 100vw;
  background-color: #f7f7f8;
  margin-bottom: auto;
  margin-top: 3.5rem;
`;

const Logo = styled.img`
  width: 30px;
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 5rem;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 0 1rem;
    flex-direction: column-reverse;
  }
`;

const ContactSection = styled.div`
  width: 60%;
  max-width: 500px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const AppsSection = styled.div``;

const Title = styled.div`
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 700;
`;

const LinkList = styled.div`
  display: flex;
  flex-direction: column;
`;

const UpperLinks = styled.div`
  display: flex;
  margin: 1rem 0;
  font-size: 16px;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, auto);
  }
`;

const SingleUpperLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  padding: 10px 0;
  font-weight: 300;
  &:hover {
    cursor: pointer;
    color: #0073ea;
  }
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, auto);
    padding-bottom: 1rem;
  }
`;

const LowerLinks = styled.div`
  display: flex;
  margin: 1rem auto;
  font-size: 14px;
`;

const SingleLowerLink = styled(Link)`
  margin: 0 0.5rem;
  color: #707070;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Footer = () => {
  return (
    <Container>
      <Section>
        <ContactSection>
          <Contact />
        </ContactSection>
        <AppsSection>
          <Title>Apps</Title>
          <LinkList>
            <SingleUpperLink to={RoutePaths.LOG_BACK_HOW_TO_USE_SITE}>
              LogBack
            </SingleUpperLink>
            <SingleUpperLink
              to={RoutePaths.MASTERPLAN_CALENDAR_HOW_TO_USE_SITE}
            >
              MasterPlan Calendar
            </SingleUpperLink>
            <SingleUpperLink to={RoutePaths.MULTI_EMBED_HOW_TO_USE_SITE}>
              Multi Embed
            </SingleUpperLink>
            <SingleUpperLink to={RoutePaths.TEXT_WIZ_HOW_TO_USE_SITE}>
              TextWiz
            </SingleUpperLink>
          </LinkList>
        </AppsSection>
      </Section>
      <Section>
        <LowerLinks>
          <SingleLowerLink to={RoutePaths.PRIVACY_POLICY}>
            Privacy Policy
          </SingleLowerLink>
          <SingleLowerLink to={RoutePaths.TERMS_OF_SERVICE}>
            Terms Of Service
          </SingleLowerLink>
        </LowerLinks>
      </Section>
    </Container>
  );
};

export default Footer;
