import styled from 'styled-components';
import { Element } from 'react-scroll';

const Table = styled.table`
  text-align: left;
  border-collapse: collapse;
  font-size: 15px;
  font-weight: 300;
`;

const TableHead = styled.thead``;

const TableHeader = styled.th`
  border-bottom: 1px solid rgb(210, 210, 210);
  padding: 1rem 0;
  &:nth-child(1) {
    width: 25%;
  }
  &:nth-child(2) {
    width: 45%;
  }
  &:nth-child(3) {
    width: 30%;
  }
  @media (max-width: 768px) {
    height: 45px;
  }
`;

const ElementRow = styled(Element)`
  border-bottom: 1px solid rgb(210, 210, 210);
  &:last-of-type {
    border-bottom: none;
  }
`;

const TableRow = styled.tr`
  display: flex;
  align-items: flex-start;
`;

const TableBody = styled.tbody``;

const TableData = styled.td`
  height: 140px;
  padding: 2rem 0;
  &:nth-child(1) {
    width: 25%;
  }
  &:nth-child(2) {
    width: 45%;
  }
  &:nth-child(3) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;
  }
  @media (max-width: 768px) {
    height: fit-content;
  }
`;

const CellText = styled.div`
  & span {
    text-decoration: none;
    color: #0073ea;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  @media (max-width: 768px) {
    margin: 1rem 1rem 1rem 0;
  }
`;

interface SupportedEventsTableProps {
  scrollToElement: (element: string) => void;
}

const SupportedEventsTable = ({
  scrollToElement,
}: SupportedEventsTableProps) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader>Event name</TableHeader>
          <TableHeader>Restore action</TableHeader>
          <TableHeader>Note</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        <ElementRow name="update-column-value">
          <TableRow>
            <TableData>
              <CellText>Update column value</CellText>
            </TableData>
            <TableData>
              <CellText>Revert a column's value to its previous value</CellText>
            </TableData>
            <TableData>
              <CellText>
                If the previous column value is equal to the current value, no
                action will be applied.
              </CellText>
              <CellText>
                See{' '}
                <span onClick={() => scrollToElement('supported-columns')}>
                  supported columns
                </span>
              </CellText>
            </TableData>
          </TableRow>
        </ElementRow>
        <ElementRow name="update-item-name">
          <TableRow>
            <TableData>
              <CellText>Update item name</CellText>
            </TableData>
            <TableData>
              <CellText>Revert a item's name to its previous name</CellText>
            </TableData>
            <TableData>
              <CellText></CellText>
            </TableData>
          </TableRow>
        </ElementRow>
        <ElementRow name="update-column-name">
          <TableRow>
            <TableData>
              <CellText>Update column name</CellText>
            </TableData>
            <TableData>
              <CellText>Revert a column's value to its previous name</CellText>
            </TableData>
            <TableData>
              <CellText></CellText>
            </TableData>
          </TableRow>
        </ElementRow>
        <ElementRow name="update-group-name">
          <TableRow>
            <TableData>
              <CellText>Update group name</CellText>
            </TableData>
            <TableData>
              <CellText>Revert a group's name to its previous name</CellText>
            </TableData>
            <TableData>
              <CellText></CellText>
            </TableData>
          </TableRow>
        </ElementRow>
        <ElementRow name="update-group-color">
          <TableRow>
            <TableData>
              <CellText>Update group color</CellText>
            </TableData>
            <TableData>
              <CellText>Revert a group's color to its previous color</CellText>
            </TableData>
            <TableData>
              <CellText></CellText>
            </TableData>
          </TableRow>
        </ElementRow>
        <ElementRow name="delete-item">
          <TableRow>
            <TableData>
              <CellText>Delete item</CellText>
            </TableData>
            <TableData>
              <CellText>Create a duplicate of the deleted item</CellText>
            </TableData>
            <TableData>
              <CellText>
                Create duplication with the same details as the deleted one,
                rather than restoring the original item.
              </CellText>
              <CellText>
                A duplicate will be created for any item that has been deleted
                within the last 30 days.
              </CellText>
            </TableData>
          </TableRow>
        </ElementRow>
        <ElementRow name="create-item">
          <TableRow>
            <TableData>
              <CellText>Create item</CellText>
            </TableData>
            <TableData>
              <CellText>Delete the created item</CellText>
            </TableData>
            <TableData>
              <CellText></CellText>
            </TableData>
          </TableRow>
        </ElementRow>
        <ElementRow name="create-column">
          <TableRow>
            <TableData>
              <CellText>Create column</CellText>
            </TableData>
            <TableData>
              <CellText>Delete the created column</CellText>
            </TableData>
            <TableData>
              <CellText>Subitem column can not be deleted</CellText>
            </TableData>
          </TableRow>
        </ElementRow>
        <ElementRow name="create-group">
          <TableRow>
            <TableData>
              <CellText>Create group</CellText>
            </TableData>
            <TableData>
              <CellText>Delete the created group</CellText>
            </TableData>
            <TableData>
              <CellText></CellText>
            </TableData>
          </TableRow>
        </ElementRow>
        <ElementRow name="move-item-to-group">
          <TableRow>
            <TableData>
              <CellText>Move item to group</CellText>
            </TableData>
            <TableData>
              <CellText>Move the item back to its previous group</CellText>
            </TableData>
            <TableData>
              <CellText></CellText>
            </TableData>
          </TableRow>
        </ElementRow>
        <ElementRow name="add-subscriber">
          <TableRow>
            <TableData>
              <CellText>Add subscriber</CellText>
            </TableData>
            <TableData>
              <CellText>Remove user from the board as subscriber</CellText>
            </TableData>
            <TableData>
              <CellText></CellText>
            </TableData>
          </TableRow>
        </ElementRow>
        <ElementRow name="remove-subscriber">
          <TableRow>
            <TableData>
              <CellText>Remove subscriber</CellText>
            </TableData>
            <TableData>
              <CellText>Add user to the board as subscriber</CellText>
            </TableData>
            <TableData>
              <CellText></CellText>
            </TableData>
          </TableRow>
        </ElementRow>
        <ElementRow name="add-owner">
          <TableRow>
            <TableData>
              <CellText>Add owner</CellText>
            </TableData>
            <TableData>
              <CellText>Remove the user's role as board owner</CellText>
            </TableData>
            <TableData>
              <CellText></CellText>
            </TableData>
          </TableRow>
        </ElementRow>
        <ElementRow name="remove-owner">
          <TableRow>
            <TableData>
              <CellText>Remove owner</CellText>
            </TableData>
            <TableData>
              <CellText>Add the user's role as board owner</CellText>
            </TableData>
            <TableData>
              <CellText></CellText>
            </TableData>
          </TableRow>
        </ElementRow>
      </TableBody>
    </Table>
  );
};

export default SupportedEventsTable;
