import styled from 'styled-components';
import Layout from '../../shared/components/Layout/Layout';
import { Element, scroller } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import homepage_image from '../../assets/images/homepage_image.jpg';
import homepage_logback from '../../assets/icons/homepage_logback.svg';
import homepage_masterplan from '../../assets/icons/homepage_masterplan.svg';
import homepage_textwiz from '../../assets/icons/homepage_textwiz.svg';
import homepage_multiembed from '../../assets/icons/homepage_multiembed.svg';
import { Analytics } from '@vercel/analytics/react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeroSection = styled.div`
  display: flex;
  margin-bottom: 3.5rem;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 3.5rem auto;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const Title = styled.div`
  margin-bottom: 3rem;
  font-size: 30px;
  font-weight: 700;
  font-family: 'Poppins';

  line-height: 1.04;
  letter-spacing: -0.03em;
`;

const NameSection = styled.div`
  margin: auto 5rem;
  text-align: left;
  @media (max-width: 768px) {
    margin: 2rem 1rem;
  }
`;

const Name = styled.div`
  font-size: 54px;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: 1.04;
  letter-spacing: -0.03em;
  /* font-feature-settings: 'ss01' 0, 'ss02' 0, 'ss03' 0; */
  @media (max-width: 768px) {
    font-size: 42px;
  }
`;

const Slogan = styled.div`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 20px;
  line-height: 34px;
  font-family: 'Inter';
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const CTAButton = styled.button`
  border: none;
  height: 45px;
  width: 150px;
  border-radius: 22.5px;
  color: white;
  font-size: 16px;
  font-family: inherit;
  background-image: linear-gradient(
    to right,
    #16a085 0%,
    #f4d03f 51%,
    #16a085 100%
  );
  background-size: 200% auto;
  transition: 0.5s;
  &:hover {
    background-position: right center;
    cursor: pointer;
    text-decoration: none;
  }
`;

const ContentSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
    height: 280px;
  }
`;

const ImageSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
    height: 350px;
  }
`;

const Image = styled.img`
  width: 50vw;
  height: 85vh;
  @media (max-width: 768px) {
    width: 100vw;
    height: 100%;
  }
`;

const AboutSection = styled.div`
  width: 60%;
  @media (max-width: 768px) {
    width: 70%;
  }
`;

const CardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
  transition: 0.3s cubic-bezier(0.6, 0.4, 0, 1);
`;

interface CardProps {
  $hoverBackgroundColor?: string;
  $hoverColor?: string;
}

const HoverText = styled.span<CardProps>`
  opacity: 0;
  position: absolute;
  bottom: 0px;
  transform: translate3d(-50%, 100%, 0);
  font-size: 28px;
  font-weight: 700;
  transition: all 0.2s cubic-bezier(0.6, 0.4, 0, 1);
  font-family: 'Poppins';
  color: ${(props) => props.$hoverColor};
`;

const Card = styled.a<CardProps>`
  position: relative;
  flex-basis: auto;
  width: 220px;
  height: 200px;
  background-color: #f7f7f8;
  margin: 0px 12px 24px;
  padding: 3rem 1.5rem;
  border-radius: 14px;
  transition: 0.3s cubic-bezier(0.6, 0.4, 0, 1), transform 0.15s ease;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.$hoverBackgroundColor};
    ${CardContent} {
      opacity: 0;
    }
    ${HoverText} {
      opacity: 1;
      bottom: 50%;
      transform: translate3d(-50%, 50%, 0);
    }
  }
  @media (max-width: 768px) {
    width: 250px;
  }
`;

const AppIcon = styled.img`
  width: 50px;
  margin-bottom: 30px;
`;

const AppTitle = styled.strong`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.04;
  letter-spacing: -0.03em;
`;

const AppSubtitle = styled.div`
  font-size: 16px;
  line-height: 1.7em;
  font-weight: 300;
  font-family: 'Inter';
`;

const Badge = styled.span`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #ffe5f4;
  color: #cc0078;

  text-transform: uppercase;
  font-variation-settings: 'wght' 700;
  font-size: 10px;
  padding: 4px 6px;
  border-radius: 4px;
  display: inline-flex;
  letter-spacing: 0.05em;
  vertical-align: middle;
  line-height: 1em;
  white-space: nowrap;
  text-decoration: none;
`;

const appList = [
  {
    icon: homepage_logback,
    link: 'https://monday.com/marketplace/listing/10000238/logback',
    name: 'LogBack',
    slogan: 'Made a mistake? Now you can finally UNDO!',
    hoverText: 'View on monday marketplace',
    hoverBackgroundColor: '#4a91814d',
    hoverColor: '#4A9180',
    isNew: false,
  },
  {
    icon: homepage_masterplan,
    link: 'https://monday.com/marketplace/listing/10000472/masterplan-calendar',
    name: 'MasterPlan Calendar',
    slogan: 'Transform items into dynamic calendar events',
    hoverText: 'View on monday marketplace',
    hoverBackgroundColor: '#e6bd5540',
    hoverColor: '#E6BE55',
    isNew: true,
  },
  {
    icon: homepage_multiembed,
    link: '',
    name: 'Multi Embed',
    slogan: 'Embed your favorite tools, effortlessly',
    hoverText: 'Coming Soon',
    hoverBackgroundColor: '#fb275c40',
    hoverColor: '#FB275D',
    isNew: true,
  },
  {
    icon: homepage_textwiz,
    link: 'https://monday.com/marketplace/listing/10000353/textwiz',
    name: 'TextWiz',
    slogan: 'AI mastery assistance for text revision',
    hoverText: 'View on monday marketplace',
    hoverBackgroundColor: '#6222aa3b',
    hoverColor: '#6122AA',
    isNew: false,
  },
];

const Home = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const scrollToElement = (element: string) => {
    scroller.scrollTo(element, {
      duration: 1200,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: isMobile ? -105 : -100,
    });
  };
  return (
    <Layout>
      <Container>
        <HeroSection>
          <ContentSection>
            <NameSection>
              <Name>Experience the power of our advanced solutions</Name>
              <Slogan>
                Leading Solutions for Streamlined Processes and Productivity
              </Slogan>
              <CTAButton onClick={() => scrollToElement('apps')}>
                Learn more
              </CTAButton>
            </NameSection>
          </ContentSection>
          <ImageSection>
            <Image src={homepage_image} />
          </ImageSection>
        </HeroSection>
        <Section>
          <Element name="apps">
            <Title>Our monday.com Marketplace Apps</Title>
            <CardList>
              {appList.map((app) => (
                <Card
                  key={app.name}
                  target="_blank"
                  rel="noreferrer"
                  href={app.link ? app.link : undefined}
                  $hoverBackgroundColor={app.hoverBackgroundColor}
                >
                  <CardContent>
                    <AppIcon src={app.icon} />
                    <AppTitle>{app.name}</AppTitle>
                    <AppSubtitle>{app.slogan}</AppSubtitle>
                    {app.isNew && <Badge>NEW</Badge>}
                  </CardContent>
                  <HoverText $hoverColor={app.hoverColor}>
                    {app.hoverText}
                  </HoverText>
                </Card>
              ))}
            </CardList>
          </Element>
        </Section>
        <Section>
          <AboutSection>
            <Element name="about">
              <Title>About us</Title>
              <Slogan>
                Our team specializes in developing innovative apps for the
                monday.com marketplace. With a focus on enhancing the
                functionality and usability of the platform, we strive to
                provide our customers with high-quality solutions that meet
                their business needs. Our team of experienced developers is
                dedicated to creating cutting-edge applications that streamline
                workflow processes, boost productivity, and drive business
                growth.
              </Slogan>
            </Element>
          </AboutSection>
        </Section>
      </Container>
      <Analytics />
    </Layout>
  );
};

export default Home;
