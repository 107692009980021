import styled from 'styled-components';
import SideBar from './SideBar';
import { Element, scroller } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import Layout from '../../../../shared/components/Layout/Layout';
import masterplan_1 from '../../../../assets/images/masterplan_1.png';
import masterplan_2 from '../../../../assets/images/masterplan_2.png';
import masterplan_3 from '../../../../assets/images/masterplan_3.png';
import masterplan_4 from '../../../../assets/images/masterplan_4.png';
import masterplan_5 from '../../../../assets/images/masterplan_5.png';
import masterplan_6 from '../../../../assets/images/masterplan_6.png';

const Container = styled.div`
  display: flex;
  margin: 2rem 5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }
`;

const SidebarSection = styled.div`
  width: 210px;
  @media (max-width: 768px) {
    width: unset;
    position: sticky;
    top: 80px;
    background-color: #ebeae6;
    border-bottom: 1px solid #ebeae6;
    backdrop-filter: blur(8px);
  }
`;

const Content = styled.div`
  width: calc(100% - 210px);
  padding: 0 5rem;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

const Header = styled.div`
  padding-bottom: 3rem;
  border-bottom: 1px solid rgb(194, 194, 194);
  @media (max-width: 768px) {
    margin: 1.5rem;
  }
`;

const Hero = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const HeroTitle = styled.div`
  font-weight: 600;
  font-size: 32px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid rgb(194, 194, 194);
  &:last-of-type {
    border-bottom: none;
  }
  @media (max-width: 768px) {
    margin: 1.5rem;
  }
`;

const GetStatrted = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Text = styled.div`
  font-weight: 300;
  text-align: justify;
  line-height: 22px;
  padding-bottom: 1rem;
`;

interface ImageProps {
  width?: number;
}

const Image = styled.img<ImageProps>`
  width: ${(props) => (props.width ? `${props.width}%` : '65%')};
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  margin: 1.5rem auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const MasterPlanHowToUseSite = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const scrollToElement = (element: string) => {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: isMobile ? -310 : -100,
    });
  };
  return (
    <Layout>
      <Container>
        <SidebarSection>
          <SideBar scrollToElement={scrollToElement} />
        </SidebarSection>
        <Content>
          <Header>
            <Hero>
              <HeroTitle>How To Use</HeroTitle>
            </Hero>
            <Text>
              Transform your monday.com experience with MasterPlan Calendar!
              Easily drag, drop, resize, and create events in a sleek calendar
              view. Perfectly sync items with precise timings and boost your
              productivity. Simplify scheduling like never before!
            </Text>
            <Text>
              This document provides instructions on utilizing the application
              and outlines its features in detail.
            </Text>
          </Header>
          <Section>
            <Element name="installation">
              <Title>Installation</Title>
              <Text>
                1. Go to monday.com app marketplace or click the button below:
              </Text>
              <Text>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://auth.monday.com/oauth2/authorize?client_id=683a850898635b63ab88424554eecdec&response_type=install"
                >
                  <img
                    alt="Add to monday.com"
                    height="42"
                    src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                  />
                </a>
              </Text>
              <Text>
                2. Search for <b>"MasterPlan Calendar"</b>
              </Text>
              <Text>
                3. Click <b>"Add to your account"</b> button
              </Text>
              <Text>
                4. Add MasterPlan Calendar to any board you want as board view.
              </Text>
              <GetStatrted>
                <Image src={masterplan_1} width={80} alt="masterplan_1" />
              </GetStatrted>
            </Element>
          </Section>
          <Section>
            <Element name="get-started">
              <GetStatrted>
                <Title>Get started</Title>
                <Text>
                  Now that you've added MasterPlan Calendar to your board, take
                  just 2 minutes to configure the initial setup for your
                  calendar.
                </Text>
                <Subtitle>Open board view settings</Subtitle>
                <Text>
                  Click the "Open Settings" button or the gear icon (top right)
                  to open the board view settings panel.
                </Text>
                <Image src={masterplan_2} width={80} alt="masterplan_2" />
                <Subtitle>Required fields</Subtitle>
                <Text>
                  To get started, configure the <b>Start date</b> and{' '}
                  <b>End date</b> fields by selecting the columns on your board
                  that will contain the start and end dates of your events.
                </Text>
                <Text>Once these are configured, you’re ready to go!</Text>
                <Subtitle>Optinal fields</Subtitle>
                <Text>
                  You can make your events look even better. Here are some
                  optional settings you can configure to add more customization
                  and enhance the visual appeal of your calendar.
                </Text>
                <Subtitle>Color by</Subtitle>
                <Text>
                  Choose a column for color-coding events, enabling quick
                  differentiation. For instance, assign colors based on a column
                  like "Status" to visually categorize events.
                </Text>
                <Text>Supported columns:</Text>
                <Text>
                  <ul>
                    <li style={{ margin: '10px 0' }}>Status</li>
                    <li style={{ margin: '10px 0' }}>Color picker</li>
                  </ul>
                </Text>
                <Image src={masterplan_3} width={80} alt="masterplan_3" />
                <Subtitle>People</Subtitle>
                <Text>
                  Add People column to display the persons related to the events
                </Text>
                <Subtitle>Link</Subtitle>
                <Text>
                  Add Link column to display any links related to the events
                </Text>
                <Subtitle>Location</Subtitle>
                <Text>
                  Add Link column to display the location of the events
                </Text>
                <Subtitle>Text</Subtitle>
                <Text>Add Text column to display extra details if needed</Text>
                <Subtitle>Numbers</Subtitle>
                <Text>
                  Add Numbers column to display extra details if needed
                </Text>
              </GetStatrted>
            </Element>
          </Section>
          <Section>
            <Element name="features">
              <Title>Features</Title>
              <Text>
                MasterPlan Calendar provides features designed for effortless
                and efficient calendar management..
              </Text>
            </Element>
            <Element name="create-event">
              <GetStatrted>
                <Subtitle>Create Event</Subtitle>
                <Text>
                  Easily add events to your calendar by selecting any time slot.
                  Simply tap or click on the desired time, input event details,
                  and there you have it! Your event is cretaed instantly.
                </Text>
                <Image src={masterplan_4} width={60} alt="masterplan_4" />
              </GetStatrted>
            </Element>
            <Element name="drag-and-drop">
              <GetStatrted>
                <Subtitle>Drag & Drop</Subtitle>
                <Text>
                  Rearrange your calendar by simply dragging any event to a new
                  time slot. Effortlessly customize your calendar layout to fit
                  your dynamic workflow.
                </Text>
                <Image src={masterplan_5} width={60} alt="masterplan_5" />
              </GetStatrted>
            </Element>
            <Element name="resize">
              <GetStatrted>
                <Subtitle>Resize</Subtitle>
                <Text>
                  Easily adjust event durations by dragging the edges of any
                  event to extend or shorten its timeframe. This allows for
                  quick and flexible scheduling adjustments.
                </Text>
                <Image src={masterplan_6} width={30} alt="masterplan_6" />
              </GetStatrted>
            </Element>
            <Element name="duplicate-event">
              <Subtitle>Duplicate Event</Subtitle>
              <Text>
                Quickly duplicate any calendar event item with a few clicks,
                saving you valuable time in scheduling.
              </Text>
            </Element>
            <Element name="delete-event">
              <Subtitle>Delete Event</Subtitle>
              <Text>
                Delete any calendar event items with a single click, keeping
                your schedule clean and organized.
              </Text>
            </Element>
          </Section>
        </Content>
      </Container>
    </Layout>
  );
};

export default MasterPlanHowToUseSite;
