import styled from 'styled-components';
import SideBar from './SideBar';
import { Element, scroller } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import Layout from '../../../../shared/components/Layout/Layout';
import SupportedEventsTable from '../shared/SupportedEventsTable';
import SupportedColumnsTable from '../shared/SupportedColumnsTable';
import initial_load_image from '../../../../assets/images/inital_load_image.png';
import selection_image from '../../../../assets/images/selection_image.png';
import filter_dates_image from '../../../../assets/images/filter_dates_image.png';
import popup_message_image from '../../../../assets/images/popup_message_image.png';
import highlight_new_logs_image from '../../../../assets/images/highlight_new_logs_image.png';

const Container = styled.div`
  display: flex;
  margin: 2rem 5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }
`;

const SidebarSection = styled.div`
  width: 20%;
  @media (max-width: 768px) {
    width: unset;
    position: sticky;
    top: 80px;
    background-color: #ffffffb5;
    border-bottom: 1px solid #ebeae6;
    backdrop-filter: blur(8px);
  }
`;

const Content = styled.div`
  width: 80%;
  padding: 0 5rem;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

const Header = styled.div`
  padding-bottom: 3rem;
  border-bottom: 1px solid rgb(194, 194, 194);
  @media (max-width: 768px) {
    margin: 1.5rem;
  }
`;

const Hero = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const HeroTitle = styled.div`
  font-weight: 600;
  font-size: 32px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid rgb(194, 194, 194);
  &:last-of-type {
    border-bottom: none;
  }
  @media (max-width: 768px) {
    margin: 1.5rem;
  }
`;

const GetStatrted = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 26px;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const Text = styled.div`
  font-weight: 300;
  text-align: justify;
  line-height: 22px;
  padding-bottom: 1rem;
`;

const Image = styled.img`
  width: 80%;
  border-radius: 5px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  margin: 1.5rem auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const LogBackHowToUseSite = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const scrollToElement = (element: string) => {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: isMobile ? -310 : -100,
    });
  };
  return (
    <Layout>
      <Container>
        <SidebarSection>
          <SideBar scrollToElement={scrollToElement} />
        </SidebarSection>
        <Content>
          <Header>
            <Hero>
              <HeroTitle>How To Use</HeroTitle>
            </Hero>
            <Text>
              LogBack is a powerful tool that allows you to restore most of the
              activity logs on your monday.com boards.
            </Text>
            <Text>
              This document provides instructions on utilizing the application
              and outlines its features in detail.
            </Text>
          </Header>
          <Section>
            <Element name="installation">
              <Title>Installation</Title>
              <Text>
                1. Go to monday.com app marketplace or click the button below:
              </Text>
              <Text>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://auth.monday.com/oauth2/authorize?client_id=15a5df7d55da9464b68113a464c56682&response_type=install"
                >
                  <img
                    alt="Add to monday.com"
                    height="42"
                    src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                  />
                </a>
              </Text>
              <Text>
                2. Search for <b>"LogBack"</b>
              </Text>
              <Text>
                3. Click <b>"Add to your account"</b> button
              </Text>
              <Text>4. Add LogBack to any board you want</Text>
            </Element>
          </Section>
          <Section>
            <Element name="get-started">
              <GetStatrted>
                <Title>Get started</Title>
                <Text>
                  Now that you've added LogBack as a board view, you can start
                  restoring activity logs. Simply click on the <b>"LogBack"</b>{' '}
                  view to open it.
                </Text>
                <Image src={initial_load_image} alt="initial-load-image" />
                <Text>
                  You'll be able to choose and restore <b>up to 100</b> activity
                  logs at once. To restore a log, just click on the activity log
                  you want to undo, then click the{' '}
                  <b>"Restore selected logs"</b> button.
                </Text>
                <Image src={selection_image} alt="selection-image" />
                <Text>
                  Once you've selected the logs you want to restore, a popup
                  message will appear to confirm your selection. To complete the
                  process, simply click the <b>"Confirm"</b> button. It's that
                  simple!
                </Text>
                <Image src={popup_message_image} alt="popup_message_image" />
                <Text>
                  LogBack makes it easy to find the activity logs you want to
                  restore by allowing you to filter them by date. Once you've
                  found the log you want to restore, simply select it and click
                  the <b>"Restore selected logs"</b> button.
                </Text>
                <Image src={filter_dates_image} alt="filter-dates-image" />
                <Text>
                  Upon completion of the restore process, new activity on the
                  board will be highlighted, indicating that the restore was
                  successful.
                </Text>
                <Image
                  src={highlight_new_logs_image}
                  alt="highlight-new-logs-image"
                />
                <Text>
                  If there were any issues with restoring a particular log, you
                  may see a notification message alerting you to the problem.
                </Text>
              </GetStatrted>
            </Element>
          </Section>
          <Section>
            <Element name="supported-events">
              <Title>Supported events</Title>
              <Text>
                The application supports a variety of events, as listed in the
                table below.
              </Text>
              <SupportedEventsTable scrollToElement={scrollToElement} />
            </Element>
          </Section>
          <Section>
            <Element name="supported-columns">
              <Title>Supported columns</Title>
              <Text>
                The following table provides a list of supported columns for
                restoring column values.
              </Text>
              <SupportedColumnsTable />
            </Element>
          </Section>
        </Content>
      </Container>
    </Layout>
  );
};

export default LogBackHowToUseSite;
