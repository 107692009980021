import styled from 'styled-components';
import Layout from '../../shared/components/Layout/Layout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
    padding: 1.5rem;
    box-sizing: border-box;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgb(194, 194, 194);
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const Text = styled.div`
  font-weight: 300;
  font-size: 14px;
  text-align: justify;
  line-height: 22px;
  padding-bottom: 1rem;
  a {
    text-decoration: none;
    margin: 0rem 0.3rem;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const List = styled.ul`
  margin: 1rem 0;
`;

const ListItem = styled.li`
  margin: 0.5rem 0;
`;

const TermsOfService = () => {
  return (
    <Layout>
      <Container>
        <Title>Terms Of Service</Title>
        <Section>
          <Text>
            Terms and conditions applicable to the use of the Services.
            <Text>
              If you have any questions or concerns please contact us at
              <a href="mailto:contact@appsolution.live">
                contact@appsolution.live
              </a>
            </Text>
            These terms and conditions outline the terms and conditions for the
            use of our services or any of our apps. By accessing this website we
            assume you accept these terms and conditions.
          </Text>
        </Section>
        <Section>
          <Subtitle>Acceptance of terms</Subtitle>
          <Text>
            The following terminology applied to these Terms and Conditions and
            all Agreements: “Client”, “You” and “Your” refers to you, the person
            log on this website and compliant to the Our terms and conditions.
            “Ourselves”, “We”, “Our” and “Us”, refers to us, the creator.
            “Party”, “Parties”, or “Us”, refers to both the Client and
            ourselves. All terms refer to the offer, acceptance, and
            consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the
            purpose of meeting the Client’s needs in respect to the provision of
            Our stated services. Any use of the above terminology or other words
            in the singular, plural, capitalization and/or he/she or they, are
            taken as interchangeable and therefore as referring to same.
          </Text>
        </Section>
        <Section>
          <Subtitle>General conditions</Subtitle>
          <Text>
            The following conditions shall apply to all Users:
            <List>
              <ListItem>
                Our Website will be used only for lawful purposes and only in a
                lawful manner. All Users agree to comply with all applicable
                laws and regulations in all applicable jurisdictions and shall
                not violate any law, any third party’s rights, or this
                Agreement.
              </ListItem>
              <ListItem>
                We may occasionally reach out to you with important information
                about your account, product updates, new features, discount
                promotions or etc.
              </ListItem>
              <ListItem>
                We has the right to monitor User communication and may disclose
                any content and information about a User as described in the
                Privacy Policy.
              </ListItem>
              <ListItem>
                Users’ access to and use of the Our Website and Our services are
                expressly conditioned upon compliance with all the terms of this
                Agreement and any applicable laws. Any violation of any of this
                Agreement shall immediately revoke User’s right to use the Our
                website. We, at its sole discretion, may limit or refuse any
                User’s access to and/or use of the Our website and services. We
                reserves the right to modify or discontinue Our website and/or
                services (or any part or feature thereof) at any time and
                without notice. User agrees that We shall not be liable to User
                or to any third party for any damages caused by or in connection
                with any limitation, refusal, modification, suspension, or
                discontinuance of Our website and services or any portion
                thereof.
              </ListItem>
            </List>
          </Text>
        </Section>
        <Section>
          <Subtitle>Information security</Subtitle>
          <Text>
            We uses commercially reasonable security safeguards and protocols to
            protect Our website. However, We does not assume any responsibility
            to any such Security Breach and does not guarantee that such
            Security Breach shall not occur or be fixed within any time frame.
          </Text>
        </Section>
        <Section>
          <Subtitle>Limitation of liability</Subtitle>
          <Text>
            We provides Our website and Our services and any information,
            content, or all other components on an "as-is" basis and for
            informational purposes only. We does not provide warranty as to
            completeness, timeliness or accuracy of any information or content
            on Our website and Our services. We disclaims any and all implied
            warranties including, without limitation, the implied warranty of
            merchantability, fitness for a particular purpose, and
            non-infringement. Without limiting the generality of the foregoing,
            We does not warrant that Our website and Our services will be
            accurate, error-free, virus-free, provided on an uninterrupted
            basis, or that it will meet any specific requirements of a User.
          </Text>
        </Section>
        <Section>
          <Subtitle>Intelletual property rights</Subtitle>
          <Text>
            All content and materials included on Our website and in Our app,
            including but not limited to text, graphics, logos, images, and
            software, are the property of Ours or our licensors and are
            protected by intellectual property laws. You may not copy, modify,
            distribute, display, license, or sell any of the content or
            materials on this website or in our services without our prior
            written consent or the consent of the respective owners of such
            content or materials. Any unauthorized use of our intellectual
            property may result in legal action. By using this website and our
            services, you acknowledge and agree that any feedback, suggestions,
            or other information provided by you to us may be used by us without
            restriction and without any compensation to you.
          </Text>
        </Section>
        <Section>
          <Subtitle>Indemnification</Subtitle>
          <Text>
            You agree to indemnify and hold harmless ourselves, our affiliates,
            officers, directors, employees, agents, and third-party service
            providers from and against any and all claims, liabilities, damages,
            losses, costs, expenses, or fees (including reasonable attorneys'
            fees) arising from your use of Our website or Our services, your
            violation of these terms of service, or your violation of any rights
            of another party. We reserve the right to assume the exclusive
            defense and control of any matter subject to indemnification by you,
            in which event you will cooperate with us in asserting any available
            defenses.
          </Text>
        </Section>
        <Section>
          <Subtitle>Updates and changes of this agreement</Subtitle>
          <Text>
            These Terms of Use including the Privacy Policy and all other
            policies governing the use of Our Website and our services may be
            modified, changed or altered at Our sole discretion, at any time and
            without prior notice. However, We will publish notices of material
            changes to this Agreement; such notices may be posted on this
            website, when they become effective. User agrees that User’s
            continued use of Our website and our services following any
            modifications, changes or alterations to this Agreement and after
            the changes take effect will constitute User’s acceptance of such
            modifications, changes or alterations and conclusively demonstrates
            User’s acceptance of such modifications, changes or alterations.
          </Text>
        </Section>
        <Section>
          <Subtitle>Severability and waiver</Subtitle>
          <Text>
            If any provision of these Terms is held to be unenforceable or
            invalid, such provision will be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under applicable law and the remaining provisions will
            continue in full force and effect.
          </Text>
          <Text>
            Except as provided herein, the failure to exercise a right or to
            require performance of an obligation under these Terms shall not
            effect a party's ability to exercise such right or require such
            performance at any time thereafter nor shall the waiver of a breach
            constitute a waiver of any subsequent breach.
          </Text>
        </Section>
        <Section>
          <Subtitle>Governing Law</Subtitle>
          <Text>
            The laws of the State of Israel will govern this Agreement, and any
            disputes arising from or related to it will be resolved solely by
            the competent courts in Tel Aviv, Israel. The User hereby agrees to
            submit to the exclusive jurisdiction and venue of such courts in
            connection with any disputes arising from or related to this
            Agreement.
          </Text>
        </Section>
      </Container>
    </Layout>
  );
};

export default TermsOfService;
